import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationGuard } from './guards/validation.guard';
import { AnalyticsModule } from './analytics/analytics.module';

@NgModule({
    imports: [CommonModule, AnalyticsModule],
    providers: [ValidationGuard],
})
export class CoreModule {}
