import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Angulartics2Module } from 'angulartics2';
import { AnalyticsService } from './analytics.service';
import { TrackEventDirective } from './trackEvent.directive';

@NgModule({
    imports: [CommonModule, Angulartics2Module],
    declarations: [TrackEventDirective],
    exports: [TrackEventDirective],
})
export class AnalyticsModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: AnalyticsModule,
            providers: [AnalyticsService],
        };
    }
}
