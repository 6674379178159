import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ValidationResponse } from './validation.model';
import { AnalyticsService } from '../analytics/analytics.service';
import { ValidationService } from './validation.service';

@Injectable()
export class ValidationGuard implements CanActivate {
    constructor(
        private router: Router,
        private analyticsService: AnalyticsService,
        private validationService: ValidationService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const id = route.queryParams.id;
        // in future returnUrl should be replaced with below success url
        // we keeping it only to support old version(stockx)
        const returnUrl = route.queryParams.returnUrl;
        const failureUrl = route.queryParams.failureUrl;
        const successUrl = route.queryParams.successUrl;
        const sig = route.queryParams.sig;
        const lang = route.queryParams.lang;
        const merchant = route.paramMap.get('merchant').toLowerCase();

        if (this.validationService.isValid()) {
            return true;
        }
        return this.validationService.validate(id, merchant, sig, returnUrl, failureUrl, successUrl).pipe(
            map((res: ValidationResponse) => {
                this.setCustomDimensions(merchant, id);
                switch (res.response_code) {
                    case 0:
                        return true;
                    case 1000:
                        return this.router.createUrlTree([merchant + '/result/verified'], {
                            queryParams: { lang: lang },
                        });
                    case 1001:
                        return this.router.createUrlTree([merchant + '/result/unverified'], {
                            queryParams: { lang: lang },
                        });
                    default:
                        return this.router.createUrlTree(['error/404']);
                }
            }),
            catchError(() => of(this.router.createUrlTree(['error/500']))),
        );
    }

    private setCustomDimensions(merchant, id) {
        this.analyticsService.setCustomDimensionsAndMetrics({
            merchant: merchant,
            orig_id: id,
        });
    }
}
