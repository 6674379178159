import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { AssetsService } from '../layout/assets/assets.service';
import { DEFAULT_ASSETS_LOCATION, LANGUAGE_PLACEHOLDER, TRANSLATIONS_PATH } from '../layout/assets/assets-paths';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader, OnDestroy {
    private readonly DEFAULT_TRANSLATIONS_PATH = `${DEFAULT_ASSETS_LOCATION}/${TRANSLATIONS_PATH}`;

    private assetsLocationSubscription: Subscription;
    private translationsPath = this.DEFAULT_TRANSLATIONS_PATH;

    constructor(private http: HttpClient, private assetsService: AssetsService) {
        this.assetsLocationSubscription = this.assetsService.merchantAssetsLocation.subscribe(
            (assetsLocation: string) => (this.translationsPath = `${assetsLocation}/${TRANSLATIONS_PATH}`),
        );
    }

    getTranslation(lang: string) {
        let url = this.translationsPath.replace(LANGUAGE_PLACEHOLDER, lang);
        return this.http.get<Translation>(url).pipe(
            catchError(() => {
                url = this.DEFAULT_TRANSLATIONS_PATH.replace(LANGUAGE_PLACEHOLDER, lang);
                return this.http.get<Translation>(url);
            }),
        );
    }

    ngOnDestroy() {
        this.assetsLocationSubscription.unsubscribe();
    }
}
