import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { MainModule } from './main/main.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { ErrorComponent } from './error/error.component';
import { SharedModule } from '../shared/shared.module';
import { NonBrandedLayoutComponent } from './non-branded-layout/non-branded-layout.component';

@NgModule({
    declarations: [LayoutComponent, NonBrandedLayoutComponent, NotFoundComponent, ErrorComponent],
    imports: [CommonModule, RouterModule, MainModule, SharedModule],
})
export class LayoutModule {}
