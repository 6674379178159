import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class StepsService {
    private showNextStep = new Subject<any>();
    private showPreviousStep = new Subject<any>();
    goToNextStep$ = this.showNextStep.asObservable();
    goToPreviousStep$ = this.showPreviousStep.asObservable();

    goToNextStep() {
        this.showNextStep.next();
    }

    goToPreviousStep() {
        this.showPreviousStep.next();
    }
}
