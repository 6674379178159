import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsModule } from './steps/steps.module';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
    imports: [CommonModule, StepsModule],
    exports: [StepsModule, LoaderComponent],
    declarations: [LoaderComponent],
})
export class SharedModule {}
