import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ValidationService } from '../../core/guards/validation.service';
import { DEFAULT_ASSETS_LOCATION, FAVICON_PATH, STYLE_PATH } from './assets-paths';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AssetsService {
    private readonly FAVICON = 'icon';
    private readonly STYLE = 'stylesheet';
    private readonly HEAD_TAG_NAME = 'head';
    private readonly LINK_TAG_NAME = 'link';

    public merchantAssetsLocation = new BehaviorSubject<string>(DEFAULT_ASSETS_LOCATION);
    public viewReady = new BehaviorSubject<boolean>(false);

    constructor(@Inject(DOCUMENT) private document: Document, private validationService: ValidationService) {}

    loadMerchantStyle() {
        if (!this.validationService.validatedData?.merchantHash) {
            this.defaultStyle();
            return;
        }
        const assetsLocation = `${environment.merchantAssetsPathPrefix}/${this.validationService.validatedData.merchantHash}`;
        this.merchantAssetsLocation.next(assetsLocation);

        this.appendLinkElement(this.FAVICON, `${assetsLocation}/${FAVICON_PATH}`);
        const styleLinkElement = this.appendLinkElement(this.STYLE, `${assetsLocation}/${STYLE_PATH}`);

        styleLinkElement.onload = () => this.viewReady.next(true);
        styleLinkElement.onerror = () => this.defaultStyle();
    }

    defaultStyle() {
        this.appendLinkElement(this.FAVICON, `${DEFAULT_ASSETS_LOCATION}/${FAVICON_PATH}`);
        setTimeout(() => this.viewReady.next(true), 0);
    }

    private appendLinkElement(rel: string, path: string) {
        const headElement = this.document.getElementsByTagName(this.HEAD_TAG_NAME)[0];
        const linkElement = this.document.createElement(this.LINK_TAG_NAME) as HTMLLinkElement;
        linkElement.rel = rel;
        linkElement.href = path;
        headElement.appendChild(linkElement);
        return linkElement;
    }
}
