import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AssetsService } from './layout/assets/assets.service';
import { AnalyticsService } from './core/analytics/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    viewReady: BehaviorSubject<boolean>;
    private routeSub: Subscription;

    constructor(
        private assetsService: AssetsService,
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute,
        private translocoService: TranslocoService,
    ) {
        this.analyticsService.startTracking();
    }

    ngOnInit(): void {
        this.routeSub = this.route.queryParams.subscribe((params) => {
            this.setLanguage(params['lang']);
        });

        this.viewReady = this.assetsService.viewReady;
        this.storeViewHeight();
    }

    @HostListener('window:resize', [])
    private onResize() {
        this.storeViewHeight();
    }

    private storeViewHeight() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    private setLanguage(language) {
        if (this.translocoService.getAvailableLangs().includes(language)) {
            this.translocoService.setActiveLang(language);
        }
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }
}
