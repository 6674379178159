import { Component, OnInit } from '@angular/core';
import { AssetsService } from '../assets/assets.service';

@Component({
    selector: 'svsl-non-branded-layout',
    templateUrl: './non-branded-layout.component.html',
    styleUrls: ['./non-branded-layout.component.scss'],
})
export class NonBrandedLayoutComponent implements OnInit {
    constructor(private assetsService: AssetsService) {}

    ngOnInit() {
        this.assetsService.defaultStyle();
    }
}
