import { NgModule } from '@angular/core';
import { TRANSLOCO_LOADER, TRANSLOCO_CONFIG, translocoConfig, TranslocoModule } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './http-loader';
import { environment } from '../../environments/environment';

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['ch', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'zh'],
                defaultLang: 'en',
                prodMode: environment.production,
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoRootModule {}
