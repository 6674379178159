import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsComponent } from './steps.component';
import { StepComponent } from './step/step.component';
import { StepsService } from './steps.service';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { AnalyticsModule } from '../../core/analytics/analytics.module';

@NgModule({
    declarations: [StepsComponent, StepComponent, ProgressBarComponent],
    imports: [CommonModule, TranslocoModule, AnalyticsModule],
    providers: [StepsService, { provide: TRANSLOCO_SCOPE, useValue: 'general' }],
    exports: [StepsComponent, StepComponent, ProgressBarComponent],
})
export class StepsModule {}
