import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'svsl-loader',
    template: ` <div class="loader-container">
        <div class="loader-animation">
            <div class="loader-circle"></div>
        </div>
    </div>`,
    styleUrls: ['./loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent {}
