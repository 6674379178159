import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ErrorComponent } from './layout/error/error.component';
import { NonBrandedLayoutComponent } from './layout/non-branded-layout/non-branded-layout.component';
import { ValidationGuard } from './core/guards/validation.guard';

export const appRoutes: Routes = [
    {
        path: 'error',
        component: NonBrandedLayoutComponent,
        children: [
            { path: '', redirectTo: '404', pathMatch: 'full' },
            { path: '404', component: NotFoundComponent },
            { path: '500', component: ErrorComponent },
        ],
    },
    {
        path: ':merchant',
        component: LayoutComponent,
        canActivate: [ValidationGuard],
        children: [
            { path: '404', component: NotFoundComponent },
            { path: '500', component: ErrorComponent },
            {
                path: 'result',
                loadChildren: () => import('./features/responses/responses.module').then((m) => m.ResponsesModule),
            },
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () =>
                    import('./features/verification/verification.module').then((m) => m.VerificationModule),
            },
        ],
    },
    { path: '**', redirectTo: 'error/404' },
];
