import { Injectable } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2Segment } from 'angulartics2/segment';
import { TranslocoService } from '@ngneat/transloco';

const SVSL_TRACK_ID = 'svsl_events';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private properties = [];
    constructor(
        private googleAnalytics: Angulartics2GoogleAnalytics,
        private segmentAnalytics: Angulartics2Segment,
        private translocoService: TranslocoService,
    ) {
        this.startTracking();
    }

    public startTracking() {
        this.googleAnalytics.startTracking();
        this.segmentAnalytics.startTracking();
    }

    public setCustomDimensionsAndMetrics(properties): void {
        this.properties = properties;
        this.segmentAnalytics.setUserProperties(properties);
        this.googleAnalytics.dimensionsAndMetrics.push({
            dimension1: properties.merchant,
            dimension2: properties.orig_id,
        });
    }

    public eventTrack(name, options): void {
        options = options || {};
        this.googleAnalyticsEventTrack(name, options);
        this.segmentAnalyticsEventTrack(name, options);
    }

    private segmentAnalyticsEventTrack(name, options) {
        const segmentProperties = {
            event_category: options.category,
            event_name: name,
            timestamp: options.label,
            page: options.page || `${window.location.pathname}#${window.location.hash.substring(1)}`,
            language: this.translocoService.getActiveLang(),
            info: options.info,
            ...this.properties,
        };
        this.segmentAnalytics.eventTrack(SVSL_TRACK_ID, segmentProperties);
    }

    private googleAnalyticsEventTrack(name, options) {
        this.googleAnalytics.eventTrack(name, {
            category: options.category,
            label: options.label,
            page: options.page || `${window.location.pathname}#${window.location.hash.substring(1)}`,
        });
    }
}
