import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AssetsService } from './assets/assets.service';

@Component({
    selector: 'svsl-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit {
    constructor(private assetsService: AssetsService) {}

    ngOnInit() {
        this.assetsService.loadMerchantStyle();
    }
}
