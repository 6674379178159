import { Component } from '@angular/core';

@Component({
    selector: 'svsl-main',
    styleUrls: ['./main.component.scss'],
    template: ` <div class="svsl-main">
        <router-outlet></router-outlet>
    </div>`,
})
export class MainComponent {}
