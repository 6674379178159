import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
    ValidationBasicInfoCustomFields,
    ValidationMerchantData,
    ValidationRequestData,
    ValidationResponse,
} from './validation.model';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    private validationRequestData: ValidationRequestData;
    private validationData: ValidationMerchantData;

    constructor(private http: HttpClient) {}

    validate(
        origId: string,
        merchant: string,
        sig: string,
        returnUrl: string,
        failureUrl: string,
        successUrl: string,
    ): Observable<ValidationResponse> {
        this.validationRequestData = { origId, merchant, sig, returnUrl };
        return this.http
            .get<ValidationResponse>(environment.apiUrl + 'validate', {
                params: {
                    orig_id: origId,
                    sig,
                    merchant,
                    ...(successUrl && { success_url: encodeURIComponent(successUrl) }),
                    ...(failureUrl && { failure_url: encodeURIComponent(failureUrl) }),
                },
            })
            .pipe(
                tap({
                    next: (res) => (this.validationData = this.getValidationMerchantData(merchant, res)),
                    error: () => (this.validationData = undefined),
                }),
            );
    }

    private getValidationMerchantData(merchant: string, res: ValidationResponse): ValidationMerchantData {
        return {
            merchant: merchant,
            merchantHash: res.merchant_hash,
            orderData: {
                email: res.order_data?.email || '',
                phone: res.order_data?.phone || '',
                creditCardLast4Digits: res.order_data?.cc_suffix,
                creditCardCompany: res.order_data?.cc_company,
            },
            failureUrl: res.failure_url,
            successUrl: res.success_url,
            basicInfoCustomFields: res.basic_info_custom_fields ? this.mapBasicInfoCustomFields(res) : null,
        };
    }

    private mapBasicInfoCustomFields(res: ValidationResponse): ValidationBasicInfoCustomFields {
        return {
            firstField: {
                name: res.basic_info_custom_fields.first_field.name,
                submissionFieldName: res.basic_info_custom_fields.first_field.submission_field_name,
                validationPattern: res.basic_info_custom_fields.first_field.validation_pattern,
                trackEventInfo: {
                    name: res.basic_info_custom_fields.first_field.track_event_info.name,
                    eventName: res.basic_info_custom_fields.first_field.track_event_info.event_name,
                },
            },
            secondField: {
                name: res.basic_info_custom_fields.second_field.name,
                submissionFieldName: res.basic_info_custom_fields.second_field.submission_field_name,
                validationPattern: res.basic_info_custom_fields.second_field.validation_pattern,
                trackEventInfo: {
                    name: res.basic_info_custom_fields.second_field.track_event_info.name,
                    eventName: res.basic_info_custom_fields.second_field.track_event_info.event_name,
                },
            },
        };
    }

    getValidationRequestData(): ValidationRequestData {
        return this.validationRequestData;
    }

    isValid(): boolean {
        return !!this.validationData;
    }

    get validatedData(): ValidationMerchantData {
        return this.validationData;
    }
}
