<main>
    <header>
        <div class="logo"></div>
    </header>
    <section class="main-content">
        <div class="content-box">
            <svsl-main></svsl-main>
        </div>
    </section>
</main>
