import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { InterceptorsModule } from './core/interceptors/interceptors.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AnalyticsModule } from './core/analytics/analytics.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AnalyticsModule.forRoot(),
        HttpClientModule,
        TranslocoRootModule,
        Angulartics2RouterlessModule.forRoot(),
        AppRoutingModule,
        InterceptorsModule,
        CoreModule,
        LayoutModule,
        SharedModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
